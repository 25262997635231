@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

* {
    list-style: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    border: 0;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}